/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';

@Injectable()
export class SyblAccountManagementHttpService {
  private dataApiServer: string;
  private authServer: string;
  private killBillServer: string;


  constructor(
    @Inject('appUrls') appUrls: any,
    private http: HttpClient,
  ) {
    this.dataApiServer = appUrls.API_HTTPS_SERVER;
    this.killBillServer = appUrls.KILLBILL_HTTPS_SERVER;
    this.authServer = appUrls.AUTH_URL;
  }

  saveSyblAgency(form, jwt) {
    const propertiesToSave = Object.getOwnPropertyNames(form);
    const saveObject: any = {};

    propertiesToSave.map((name) => {
      if (form[name] !== undefined) {
        saveObject[name] = form[name];
      }
    });

    const body = {
      active_seats: form.active_seats,
      address1: form.address1,
      address2: form.address2,
      agency_id: form.agency_id,
      agency_name: form.agency_name,
      billingPeriod: form.billingPeriod,
      city: form.city,
      email: form.email,
      country: form.country,
      currency: form.currency,
      date_time: form.date_time,
      dateModified: form.dateModified,
      employeeCount: form.employeeCount,
      gpsLocation: form.gpsLocation,
      inactive_seats: form.inactive_seats,
      kill_bill_id: form.kill_bill_id,
      latitude: form.latitude,
      licenseType: form.licenseType,
      licensedStates: form.licensedStates,
      longitude: form.longitude,
      naicsCodes: form.naicsCodes,
      personalLines: form.personalLines,
      phaseType: form.phaseType,
      phone: form.phone,
      postalCode: form.postalCode,
      productCategory: form.productCategory,
      purchased_seats: form.purchased_seats,
      rnCodes: form.rnCodes,
      shippingAddress1: form.shippingAddress1,
      shippingAddress2: form.shippingAddress2,
      shippingCity: form.shippingCity,
      shippingCountry: form.shippingCountry,
      shippingGpsLocation: form.shippingGpsLocation,
      shippingLatitude: form.shippingLatitude,
      shippingLongitude: form.shippingLongitude,
      shippingPhone: form.shippingPhone,
      shippingPostalCode: form.shippingPostalCode,
      shippingState: form.shippingState,
      sourceType: form.sourceType,
      state: form.state,
      subscriptionState: form.subscriptionState,
      syblUsers: form.syblUsers,
      topCarriers: form.topCarriers,
      uuid: form.uuid,
      yearlyRevenue: form.yearlyRevenue,
      msg_text: form.msg_text,
      context_filter: form.context_filter,
      jwt: jwt,
    };
    const url = this.dataApiServer + '/api-server/sybl-account/agency/save';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  saveSyblAgencyUser(form, jwt) {
    const body = {
      accountStatus: form.accountStatus,
      email: form.email,
      firstName: form.firstName,
      kill_bill_id: form.kill_bill_id,
      lastName: form.lastName,
      permissions: form.permissions,
      user_id: form.user_id,
      jwt: jwt,
    };
    const url =
      this.dataApiServer + '/api-server/sybl-account/agency/save-user';

    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  syblTeamCreationValidationEmail(form) {
    const url =
      this.dataApiServer + '/api-server/sybl-account/team/creation-email';
    return this.http.post(url, form, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((e) => {
        throw e
      })
    );

  }

  getSyblSubscriptionInfo(agency_id, user_id, tenant, page) {

    const url =
      this.killBillServer +
      '/kill-bill/agency/get-subscription-info';
    const params = new HttpParams()
      .set('agency_id', agency_id)
      .set('user_id', user_id)
      .set('tenant', tenant);

    const options = {
      params: params,
    };

    return this.http.get<any>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      }),
    );
  }

  getSyblPaymentMethod(agency_id, user_id, tenant) {
    const url =
      this.killBillServer + '/kill-bill/agency/get-payment-method';
    const params = new HttpParams()
      .set('agency_id', agency_id)
      .set('user_id', user_id)
      .set('tenant', tenant);

    const options = {
      params: params,
    };

    return this.http.get<any>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      }),
    );
  }

  blockKillBillAccount(account_id, agency_id, user_id) {
    const body = {
      account_id: account_id,
      agency_id: agency_id,
      user_id: user_id,
    };

    const url =
      this.dataApiServer + '/api-server/sybl-account/block/account-id';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  syblIndividualCreationValidationEmail(form) {
    const body = {
      tenant: form.tenant,
      email: form.email,
      agency_id: form.agency_id,
      agency_name: form.agency_name,
      firstName: form.firstName,
      lastName: form.lastName,
      address1: form.address1,
      address2: form.address2,
      city: form.city,
      state: form.state,
      postalCode: form.postalCode,
      productId: form.productId,
      name: form.name,
      killBillId: form.killBillId,
      stripeTestId: form.stripeTestId,
      stripeProdId: form.stripeProdId,
      price: form.price,
      lengthOfTime: form.lengthOfTime,
      user_id: form.user_id,
      nameOnCard: form.nameOnCard,
      creditCard: form.creditCard,
      expDate: form.expDate,
      cardType: form.cardType,
      cvc: form.cvc,
    };

    const url =
      this.dataApiServer + '/api-server/sybl-account/individual/creation-email';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  resendIndividualToken(form) {
    const url =
      this.dataApiServer + '/api-server/sybl-account/individual/resend-token';

    const body = {
      agency_id: form.agency_id,
      old_token: form.token,
      email: form.email,
    };

    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  resendTeamToken(form) {
    const url =
      this.dataApiServer + '/api-server/sybl-account/team/resend-token';

    const body = {
      agency_id: form.agency_id,
      old_token: form.token,
      email: form.email,
    };

    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  getSyblAgencyById(agency_id: string) {
    const url = this.dataApiServer + '/api-server/sybl-account/agency/get';
    const params = new HttpParams().set('agency_id', agency_id);
    const options = {
      params: params,
    };

    return this.http.get<any>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      }),
    );
  }

  inviteTeamMembers(
    agency_id: string,
    tenant: string,
    kill_bill_id: string,
    userInfos: Array<{
      email: string;
      tenant: string;
      firstName: string;
      lastName: string;
      permissions: any;
      accountStatus: any;
    }>,
  ) {
    const body = {
      agency_id: agency_id,
      userInfos: userInfos,
      tenant: tenant,
      kill_bill_id: kill_bill_id,
    };

    const url =
      this.authServer + '/auth/api-server/sybl-account/team/invite-members';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  getSyblAgencyWithUser(agency_id: string, user_id: string) {
    const url =
      this.dataApiServer + '/api-server/sybl-account/agency/get-with-user';

    const params = new HttpParams()
      .set('agency_id', agency_id)
      .set('user_id', user_id);

    const options = {
      params: params,
    };

    return this.http.get<any>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      }),
    );
  }

  getSyblAgencyForUser(user_id: string): Observable<any> {
    const url =
      this.dataApiServer + '/api-server/sybl-account/agency/get-for-user';

    const params = new HttpParams().set('user_id', user_id);

    const options = {
      params: params,
    };

    return this.http.get<any>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        console.warn('errrr Getting Agency for User', err);
        return err; //
      }),
    );
  }

  getSyblAgencyForToken(token: string) {
    const url =
      this.dataApiServer +
      '/api-server/sybl-account/agency/get-agency-for-token';

    const params = new HttpParams().set('token', token);

    const options = {
      params: params,
    };

    return this.http.get<any>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      }),
    );
  }

  userFilterSearch(filterForm, agency_id) {
    const apiUrl =
      this.dataApiServer + '/api-server/admin/agencies/get-filtered-pagination';

    const fieldOne = filterForm.fieldOne;
    const pageIndex = filterForm.pageIndex;
    const pageSize = filterForm.pageSize;
    const searchMultipleProperties = filterForm.searchMultipleProperties;
    const searchOperator = filterForm.searchOperator;
    const searchOperatorTwo = filterForm.searchOperatorTwo;
    const sortBy = filterForm.sortBy;
    const sortDirection = filterForm.sortDirection;
    const syblPermissions = filterForm.syblPermissions;
    const accountStatus = filterForm.accountStatus;

    const options = {
      params: new HttpParams()
        .set('fieldOne', fieldOne)
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('agency_id', agency_id)
        .set('searchMultipleProperties', searchMultipleProperties)
        .set('searchOperator', searchOperator)
        .set('searchOperatorTwo', searchOperatorTwo)
        .set('sortBy', sortBy)
        .set('sortDirection', sortDirection)
        .set('syblPermissions', syblPermissions)
        .set('accountStatus', accountStatus),
    };
    //return this.http.post(apiUrl, filterForm, { withCredentials: true })
    return this.http.get(apiUrl, options).pipe(
      map((response: any) => {
        // If did not respond with a user return an error.
        if (response.error != null) {
          const error = {
            authAttempt: response.error.authAttempt,
            wrongPassword: response.error.wrongPassword,
            lockUntil: response.error.lockUntil,
            tries: response.error.tries,
          };

          return { error };
        } else return response;
      }),
      catchError((err) => {
        console.warn('Error on Company', err);
        return err;
      }),
    );
  }

  userSelectedPropSearch(searchForm, agency_id) {
    const apiUrl =
      this.dataApiServer +
      '/api-server/admin/agenciess/get-selected-field-pagination';

    const searchField = searchForm.searchField;
    const fieldOne = searchForm.fieldOne;
    const pageIndex = searchForm.pageIndex;
    const pageSize = searchForm.pageSize;
    const searchMultipleProperties = searchForm.searchMultipleProperties;
    const searchOperator = searchForm.searchOperator;
    const searchOperatorTwo = searchForm.searchOperatorTwo;
    const sortBy = searchForm.sortBy;
    const sortDirection = searchForm.sortDirection;
    const syblPermissions = searchForm.syblPermissions;
    const accountStatus = searchForm.accountStatus;

    const options = {
      params: new HttpParams()
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('agency_id', agency_id)
        .set('fieldOne', fieldOne)
        .set('searchField', searchField)
        .set('searchMultipleProperties', searchMultipleProperties)
        .set('searchOperator', searchOperator)
        .set('searchOperatorTwo', searchOperatorTwo)
        .set('sortBy', sortBy)
        .set('sortDirection', sortDirection)
        .set('syblPermissions', syblPermissions)
        .set('accountStatus', accountStatus),
    };
    //return this.http.post(apiUrl, searchForm, { withCredentials: true })

    return this.http.get(apiUrl, options).pipe(
      map((response: any) => {
        // If did not respond with a user return an error.
        if (response.error != null) {
          const error = {
            authAttempt: response.error.authAttempt,
            wrongPassword: response.error.wrongPassword,
            lockUntil: response.error.lockUntil,
            tries: response.error.tries,
          };

          return { error };
        } else return response;
      }),
      catchError((err) => {
        return err;
      }),
    );
  }

  getNaicsCodes() {
    const apiUrl = this.dataApiServer + '/api-server/sybl-api/codes/naics';

    return this.http.get(apiUrl).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  getRNCodes() {
    const apiUrl =
      this.dataApiServer + '/api-server/sybl-api/codes/rn-coverage';

    return this.http.get(apiUrl).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  getKillBillPrices(tenant) {
    const apiUrl = this.killBillServer + '/kill-bill/catalog';

    const params = new HttpParams().set('tenant', tenant);

    const options = {
      params: params,
    };

    return this.http.get(apiUrl, options).pipe(
      map((response: any) => {

        return response;
      }),
    );
  }

  getStripeSession(data: { sessionId: string }) {
    const apiUrl = `${this.dataApiServer}/api-server/stripe/get-session-by-id`
    return this.http.post<any>(apiUrl, data);
  }
}
